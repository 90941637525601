import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faCirclePlay } from '@fortawesome/pro-regular-svg-icons/faCirclePlay';
import { faCirclePause } from '@fortawesome/pro-regular-svg-icons/faCirclePause';
import { faVolumeHigh } from '@fortawesome/pro-regular-svg-icons/faVolumeHigh';
import { faVolumeSlash } from '@fortawesome/pro-regular-svg-icons/faVolumeSlash';

import { fadeIn } from 'libs/animations';
import useWindow from 'context/useWindow';
import useHasMounted from 'context/useHasMounted';
import MaxWidth from 'layouts/max-width';
import Spacing from 'layouts/Spacing';
import VideoIframe from './VideoIframe';
import ScreenReaderText from 'components/screen-reader-text';
import BlankButton from 'components/forms/BlankButton';
import LazyImage from 'components/LazyImage';
import InputRange from 'components/forms/InputRange';

const Wrap = styled.div`
	position: relative;
`;

const Container = styled.div`
	margin-bottom: 5px;
	border-radius: ${p => p.theme.utils.borderRadius};
	overflow: hidden;
	position: relative;
`;

const VideoEl = styled.video`
	width: 100%;
	display: block;
	border-radius: ${p => p.theme.utils.borderRadius};
	${p =>
		(p.$hide &&
			css`
				opacity: 0;
				position: absolute;
				top: 0;
			`) ||
		css`
			opacity: 1;
			border: 1px solid ${p => p.theme.colors.grey300};
			position: relative;
			top: 0;
		`}
`;

const VideoPreview = styled.div`
	border-radius: ${p => p.theme.utils.borderRadius};
	width: 100%;
	height: 100%;
	text-align: center;
	.lazy-image {
		position: relative !important;
	}
`;

const VideoWrapper = styled.div`
	width: 100%;
	position: relative;
	&:focus,
	&:focus-within {
		outline: 0;
	}
	.react-player iframe {
		border-radius: ${p => p.theme.utils.borderRadius};
	}
	${p =>
		p.isplaying === 'true' &&
		css`
			&:hover ~ .video__controls {
				bottom: 0;
				opacity: 1;
				visibility: visible;
				pointer-events: all;
				&:after {
					background-image: linear-gradient(
						180deg,
						rgba(40, 41, 44, 0),
						rgba(0, 0, 0, 0.8) 100%
					);
				}
			}
		`}
`;

const TriggerArea = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: ${p => p.theme.utils.borderRadius};
	transition: all 350ms ease;
	&:hover {
		button {
			transform: scale(1.05);
			background: ${p => transparentize(0.6, p.theme.colors.black)};
		}
	}
	button {
		display: flex;
		justify-content: center;
		align-items: center;
		background: ${p => transparentize(0.6, p.theme.colors.black)};
		width: 120px;
		height: 120px;
		border-radius: 120px;
		${p =>
			p.theme.media.smallOnly(css`
				width: 80px;
				height: 80px;
				border-radius: 80px;
			`)}

		svg {
			width: 45px;
			height: 52px;
			${p =>
				p.theme.media.smallOnly(css`
					width: 30px;
					height: 34.66px;
				`)}
		}
	}
	${p =>
		(p.hide &&
			css`
				opacity: 0;
			`) ||
		css`
			opacity: 1;
		`}
`;

const ToggleVideoDesc = styled(BlankButton)`
	z-index: 1;
	width: max-content;
	font-size: 14px;
	line-height: 22px;
	animation-name: ${fadeIn};
	animation-duration: 500ms;
	animation-delay: 1000ms;
	animation-fill-mode: forwards;
	opacity: 0;
	${p =>
		p.theme.media.mediumDown(css`
			padding: 0 !important;
		`)}
`;

const Desc = styled.div`
	height: 0;
	width: 0;
	visibility: hidden;
	text-align: center;
	background: ${p => p.theme.colors.white};
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 13;
	transition: 450ms;
	border: 2px solid ${p => p.theme.colors.grey300};
	border-radius: ${p => p.theme.utils.borderRadius};
	text-align: center;
	div {
		width: 100%;
		opacity: 0;
		transition: opacity 3s, transform 450ms;
		height: 100%;
		overflow: auto;
		transform: scale(0);
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		padding: 50px 40px;
		border-radius: ${p => p.theme.utils.borderRadius};
		${p =>
			p.theme.media.mediumOnly(css`
				padding: 40px 30px;
			`)}
		${p =>
			p.theme.media.smallOnly(css`
				padding: 20px;
				font-size: 15px;
				line-height: 23px;
				text-align: left;
			`)}
	}
	${p =>
		p.show === 'true' &&
		css`
			height: 100%;
			width: 100%;
			visibility: visible;
			div {
				opacity: 1;
				transform: scale(1);
			}
		`};
`;

/**
 * Represents the video component
 * @param {object} props - The properties for the component
 * @param {boolean} progressBar - Wheter to show the progress bar or not
 * @param {boolean} muteButton - Wheter to show the mute button or not
 * @param {object} file - The video file
 * @param {object} preview - The preview for the video
 * @param {object} description - The description for the video
 * @param {object} spacing - The spacing for the component
 * @param {object} title - The title for the component
 * @param {object} youtubeVimeoUrl - The youtube/vimeo url for the video
 * @returns {JSX.Element}
 */
export default function Video({
	progressBar = true,
	muteButton = true,
	...props
}) {
	const videoElement = useRef(null);
	const [showVideoDesc, setShowVideoDesc] = useState(false);

	const {
		setPlayerState,
		playerState,
		togglePlay,
		handleOnTimeUpdate,
		handleVideoProgress,
		toggleMute,
	} = useVideoPlayer(videoElement);
	const hasMounted = useHasMounted();
	const { browser } = useWindow();

	// If not mounted, or no video, return nothing
	if (!hasMounted || !(props?.file?.file?.url || props?.youtubeVimeoUrl))
		return <></>;

	// If Safari or youtube/vimeo-url, use iframe
	if (browser === 'Safari' || props?.youtubeVimeoUrl) {
		return (
			<MaxWidth
				data-cy="component__video"
				className="component__video max-width">
				<Spacing style={{ width: '100%' }} {...props}>
					<Wrap>
						<Container>
							<VideoIframe
								src={
									props?.file?.file?.url ||
									props?.youtubeVimeoUrl
								}
								preview={props?.preview?.file?.url}
							/>
						</Container>
					</Wrap>
				</Spacing>
			</MaxWidth>
		);
	}

	return (
		<MaxWidth
			data-cy="component__video"
			className="component__video max-width">
			<Spacing {...props}>
				<Wrap>
					<Container>
						<VideoWrapper
							aria-label={props?.title}
							isplaying={playerState?.isPlaying?.toString()}>
							<VideoEl
								src={props?.file?.file?.url}
								ref={videoElement}
								onTimeUpdate={handleOnTimeUpdate}
								onClick={togglePlay}
								/* autoPlay={true} */
								$hide={
									props?.preview?.file?.url &&
									!playerState?.isPlaying &&
									playerState?.progress === 0
								}
								muted
							/>

							{!playerState?.isPlaying &&
								playerState?.progress === 0 && (
									<>
										{props?.preview?.file?.url && (
											<VideoPreview className="video__preview">
												<LazyImage
													{...props?.preview}
													load="instant"
													ratio="16:10"
												/>
												<ScreenReaderText>
													Start video "{props?.title}"
												</ScreenReaderText>
											</VideoPreview>
										)}
										<TriggerArea
											role="button"
											onClick={e => {
												e.preventDefault();
												togglePlay();
											}}
											tabIndex={
												props?.description
													?.childMarkdownRemark
													?.rawMarkdownBody &&
												showVideoDesc
													? '-1'
													: '0'
											}>
											<BlankButton title="Start videoen">
												<FontAwesomeIcon
													icon={faPlay}
													size="sm"
													style={{
														color: 'white',
													}}
												/>
											</BlankButton>
										</TriggerArea>
									</>
								)}
						</VideoWrapper>

						<VideoControls
							setPlayerState={setPlayerState}
							playerState={playerState}
							togglePlay={togglePlay}
							handleVideoProgress={handleVideoProgress}
							toggleMute={toggleMute}
							showVideoDesc={showVideoDesc}
							progressBar={progressBar}
							muteButton={muteButton}
							{...props}
						/>

						{props?.description?.childMarkdownRemark
							?.rawMarkdownBody && (
							<Desc
								id="video-desc"
								show={showVideoDesc?.toString()}>
								<div>
									{
										props?.description?.childMarkdownRemark
											?.rawMarkdownBody
									}
								</div>
							</Desc>
						)}
					</Container>
				</Wrap>
				{props?.description?.childMarkdownRemark?.rawMarkdownBody && (
					<ToggleVideoDesc
						aria-controls="video-desc"
						aria-expanded={showVideoDesc}
						aria-haspopup="true"
						onClick={() => setShowVideoDesc(!showVideoDesc)}>{`${
						showVideoDesc ? 'Skjul' : 'Vis'
					} tekstalternativ til videoen`}</ToggleVideoDesc>
				)}
			</Spacing>
		</MaxWidth>
	);
}

const Controls = styled.div`
	position: absolute;
	bottom: -100px;
	opacity: 0;
	visibility: hidden;
	left: 0;
	z-index: 12;
	width: 100%;
	height: 23.5%;
	transition: all 0.35s ease;
	overflow: hidden;
	display: flex;
	align-items: flex-end;
	pointer-events: none;
	${p =>
		p.isplaying === 'true' &&
		css`
			&:hover {
				bottom: 0;
				opacity: 1;
				visibility: visible;
				pointer-events: all;
				&:after {
					background-image: linear-gradient(
						180deg,
						rgba(40, 41, 44, 0),
						rgba(0, 0, 0, 0.8) 100%
					);
				}
			}
		`}
	${p =>
		p.isplaying === 'false' &&
		p.progress > 0 &&
		css`
			bottom: 0;
			opacity: 1;
			visibility: visible;
			pointer-events: all;
			&:after {
				background-image: linear-gradient(
					180deg,
					rgba(40, 41, 44, 0),
					rgba(0, 0, 0, 0.8) 100%
				);
			}
		`}
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 1;
		transition: all 0.7s ease;
		border-radius: ${p =>
			`0 0 ${p.theme.utils.borderRadius} ${p.theme.utils.borderRadius}`};
	}
`;

const Actions = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 2;
	position: relative;
	padding: 0 20px 20px;
	gap: 20px;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 0 10px 10px;
			gap: 5px;
		`)}
	input[type='range'] {
		margin-top: 20px;
	}
`;

const PlayPauseButton = styled(BlankButton)`
	width: 31px;
	height: 39px;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 0;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	transition: all 350ms ease;
	&:hover {
		transform: scale(1.05);
	}
	&:focus {
		outline: 0;
	}
	svg {
		width: 21px;
		height: 21px;
		display: block;
	}
`;

const MuteBtn = styled(BlankButton)`
	transition: all 350ms ease;
	padding: 0;
	border-radius: 100%;
	width: 37px;
	height: 39px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	svg {
		width: 27px;
		height: 21px;
	}
	&:hover,
	&:focus {
		transform: scale(1.2);
	}
	&:focus {
		outline: 0;
	}
`;

/**
 * Represents the video controls
 * @param {object} props - The properties for the component
 * @param {object} playerState - The state of the video player
 * @param {function} togglePlay - The function to toggle play/pause
 * @param {function} handleVideoProgress - The function to handle video progress
 * @param {function} toggleMute - The function to toggle mute
 * @param {boolean} showVideoDesc - Wheter to show the video description or not
 * @param {boolean} progressBar - Wheter to show the progress bar or not
 * @param {boolean} muteButton - Wheter to show the mute button or not
 * @param {object} description - The description for the video
 * @param {...object} props - The rest of the properties for the component
 * @returns {JSX.Element}
 **/
function VideoControls({
	setPlayerState,
	playerState,
	togglePlay,
	handleVideoProgress,
	toggleMute,
	showVideoDesc,
	progressBar,
	muteButton,
	...props
}) {
	const rangeRef = useRef(null);

	return (
		<Controls
			className="video__controls"
			isplaying={playerState?.isPlaying?.toString()}
			progress={playerState?.progress}>
			<Actions>
				<PlayPauseButton
					title={
						playerState?.isPlaying
							? 'Sett videoen på pause'
							: 'Start videoen'
					}
					onClick={e => {
						e.preventDefault();
						togglePlay();
					}}>
					<FontAwesomeIcon
						icon={
							playerState?.isPlaying
								? faCirclePause
								: faCirclePlay
						}
						size="sm"
						style={{
							color: 'white',
						}}
					/>
				</PlayPauseButton>

				{progressBar &&
					!!(
						playerState?.isPlaying ||
						(!playerState?.isPlaying && playerState?.progress > 0)
					) && (
						<InputRange
							ref={rangeRef}
							mode="dark"
							progress={playerState?.progress}
							onChange={e => handleVideoProgress(e)}
							tabIndex={
								props?.description?.childMarkdownRemark
									?.rawMarkdownBody && showVideoDesc
									? '-1'
									: '0'
							}
						/>
					)}

				{muteButton &&
					!!(
						playerState?.isPlaying ||
						(!playerState?.isPlaying && playerState?.progress > 0)
					) && (
						<MuteBtn
							onClick={toggleMute}
							tabIndex={
								props?.description?.childMarkdownRemark
									?.rawMarkdownBody && showVideoDesc
									? '-1'
									: '0'
							}
							text="Skru av lyden på videoen">
							<>
								<FontAwesomeIcon
									icon={
										!playerState.isMuted
											? faVolumeHigh
											: faVolumeSlash
									}
									size="lg"
									style={{
										color: 'white',
									}}
								/>
								<ScreenReaderText>
									{!playerState.isMuted
										? 'Skru av lyden på videoen'
										: 'Skru på lyden på videoen'}
								</ScreenReaderText>
							</>
						</MuteBtn>
					)}
			</Actions>
		</Controls>
	);
}

/**
 * Custom hook for the video player
 * @param {object} videoElement - The video element
 * @returns {object} - The state and functions for the video player
 **/
export function useVideoPlayer(videoElement) {
	const [playerState, setPlayerState] = useState({
		isPlaying: false,
		progress: 0,
		speed: 1,
		isMuted: false,
	});

	const togglePlay = () => {
		setPlayerState({
			...playerState,
			isPlaying: !playerState.isPlaying,
		});
	};

	useEffect(() => {
		if (!videoElement?.current) return;
		playerState.isPlaying
			? videoElement.current.play()
			: videoElement.current.pause();
	}, [playerState.isPlaying, videoElement]);

	const handleOnTimeUpdate = () => {
		if (!videoElement.current) return;
		const progress =
			(videoElement.current.currentTime / videoElement.current.duration) *
			100;
		setPlayerState({
			...playerState,
			progress,
		});
	};

	const handleVideoProgress = event => {
		const manualChange = Number(event.target.value);
		videoElement.current.currentTime =
			(videoElement.current.duration / 100) * manualChange;
		setPlayerState({
			...playerState,
			progress: manualChange,
		});
	};

	const toggleMute = () => {
		setPlayerState({
			...playerState,
			isMuted: !playerState.isMuted,
		});
	};

	useEffect(() => {
		if (!videoElement?.current) return;

		if (playerState.isMuted) {
			videoElement.current.muted = true;
		} else {
			videoElement.current.muted = false;
		}
	}, [playerState.isMuted, playerState.isPlaying, videoElement]);

	// Loop video
	useEffect(() => {
		if (playerState?.progress === 100) {
			setTimeout(() => {
				setPlayerState({
					...playerState,
					progress: 0,
				});
				videoElement.current.play();
			}, 2000);
		}
	}, [playerState, playerState.progress, videoElement]);

	return {
		setPlayerState,
		playerState,
		togglePlay,
		handleOnTimeUpdate,
		handleVideoProgress,
		toggleMute,
	};
}
