import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faCirclePause } from '@fortawesome/pro-regular-svg-icons/faCirclePause';

import {
	getYoutubeImageThumbnail,
	isYoutubeOrVimeoLink,
	youtubeParser,
} from 'libs/content';
import ScreenReaderText from 'components/screen-reader-text';

const VideoWrapper = styled.div`
	margin-bottom: 1.5em;
	position: relative;
	padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
	.react-player {
		position: absolute;
		top: 0;
		left: 0;
		border: 1px solid ${p => p.theme.colors.grey300};
		border-radius: ${p => p.theme.utils.borderRadius};
	}
`;

const PlayButton = styled.button`
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 3;
	border: 0;
	padding: 20px;
	background: transparent;
	cursor: pointer;
	> div {
		display: flex;
		justify-content: center;
		align-items: center;
		background: ${p => transparentize(0.6, p.theme.colors.black)};
		width: 120px;
		height: 120px;
		border-radius: 120px;
		margin: 0 auto;
		${p =>
			p.theme.media.smallOnly(css`
				width: 80px;
				height: 80px;
				border-radius: 80px;
			`)}

		svg {
			width: 45px;
			height: 52px;
			${p =>
				p.theme.media.smallOnly(css`
					width: 30px;
					height: 34.66px;
				`)}
		}
	}
	&:hover {
		div {
			transform: scale(1.05);
			background: ${p => transparentize(0.6, p.theme.colors.black)};
		}
	}
`;

/**
 * Represents a video iframe
 * @param {string} src - The video source
 */
export default function VideoIframe({ src, preview }) {
	const [play, setPlay] = useState(false);
	const [videoThumbUrl, setVideoThumbUrl] = useState('' || true);
	const isYoutube =
		isYoutubeOrVimeoLink(src) &&
		(src.indexOf('youtu.be') !== -1 || src.indexOf('youtube.com') !== -1);
	const youtubeId = youtubeParser(src);

	function checkYoutubeThumb(e) {
		if (!isYoutube) return;
		const thumb = getYoutubeImageThumbnail(e);
		setVideoThumbUrl(thumb);
	}

	if (!src) return <></>;
	return (
		<VideoWrapper>
			<ReactPlayer
				className="react-player"
				url={src}
				loop={true}
				playing={play}
				controls={true}
				light={(isYoutube && videoThumbUrl) || preview}
				width="100%"
				height="100%"
				onClickPreview={() => setPlay(true)}
				playIcon={
					<PlayButton
						onClick={e => setPlay(true)}
						aria-label={`Klikk for å starte videoen`}
						aria-controls="video-wrapper">
						{!play ? (
							<>
								<div>
									<FontAwesomeIcon
										icon={faPlay}
										size="5x"
										style={{ color: 'white' }}
									/>
								</div>
								<ScreenReaderText>
									Start videoen
								</ScreenReaderText>
							</>
						) : (
							<>
								<div>
									<FontAwesomeIcon
										icon={faCirclePause}
										size="5x"
										style={{ color: 'white' }}
									/>
								</div>
								<ScreenReaderText>
									Sett videoen på pause
								</ScreenReaderText>
							</>
						)}
					</PlayButton>
				}
			/>
			{isYoutube && youtubeId && (
				<img
					onLoad={checkYoutubeThumb}
					src={`https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`}
					alt="Youtube Bilde"
					style={{
						visibility: 'hidden',
						opacity: 0,
						position: 'absolute',
						top: 0,
						left: 0,
					}}
				/>
			)}
		</VideoWrapper>
	);
}
